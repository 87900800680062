import type { components } from "@frend-digital/centra-types/oas";
import type {
	BaseSelection,
	BaseSelectionItem,
} from "@frend-digital/centra-types/selection";
import { sendGTMEvent } from "@next/third-parties/google";

export interface GTMProduct {
	item_id: string;
	item_name: string;
	affiliation?: string;
	coupon?: string;
	discount?: number;
	index?: number;
	item_brand?: string;
	item_category?: string;
	item_category2?: string;
	item_category3?: string;
	item_category4?: string;
	item_category5?: string;
	item_list_id?: string;
	item_list_name?: string;
	item_variant?: string;
	location_id?: string;
	price: number;
	quantity?: number;
}

export function addToCart(data: {
	currency: string;
	/**
	 * Set value to the sum of (price * quantity) for all items in items. Don't include shipping or tax.
	 */
	value: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "add_to_cart", ecommerce: data });
}

export function addToWishlist(data: {
	currency: string;
	/**
	 * Set value to the sum of (price * quantity) for all items in items. Don't include shipping or tax.
	 */
	value: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "add_to_wishlist", ecommerce: data });
}

export function beginCheckout(data: {
	currency: string;
	value: number;
	items: GTMProduct[];
	coupon?: string;
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "begin_checkout", ecommerce: data });
}

export function removeFromCart(data: {
	currency: string;
	value: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "remove_from_cart", ecommerce: data });
}

export function purchase(data: {
	currency: string;
	/**
	 * Set value to the sum of (price * quantity) for all items in items. Don't include shipping or tax.
	 */
	value: number;
	transaction_id: string;
	coupon?: string;
	/**
	 * Cost of shipping
	 */
	shipping?: number;
	tax?: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "purchase", ecommerce: data });
}

export function viewCart(ecommerce: {
	currency: string;
	value: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "view_cart", ecommerce });
}

export function viewItem(ecommerce: {
	currency: string;
	value: number;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "view_item", ecommerce });
}

export function viewItemList(ecommerce: {
	currency: string;
	item_list_id: string;
	item_list_name: string;
	items: GTMProduct[];
}) {
	sendGTMEvent({ ecommerce: null });
	sendGTMEvent({ event: "view_item_list", ecommerce });
}

export function search(search_term: string) {
	sendGTMEvent({ event: "search", search_term });
}

export function signUp(method: string) {
	sendGTMEvent({
		event: "sign_up",
		method,
	});
}

export function selectionItemToGTAGProduct(item: BaseSelectionItem): GTMProduct {
	const categories = item.product?.categoryName
		? Object.fromEntries(
				item.product.categoryName
					.map((name, i) => [`item_category${i === 0 ? "" : i + 1}`, name])
					.slice(0, 4),
			)
		: {};
	return {
		item_id: item.sku!,
		item_name: item.product?.name!,
		price: item.priceEachAsNumber!,
		quantity: item.quantity,
		item_brand: item.product?.brand,
		...categories,
		item_variant: item.product?.variantName,
	};
}

export function pageView() {
	sendGTMEvent({ event: "page_view", page: window.location.href });
}

export const gtag = {
	addToCart,
	removeFromCart,
	addToWishlist,
	beginCheckout,
	viewCart,
	viewItem,
	viewItemList,
	purchase,
	search,
	signUp,
	pageView,
};

export const centraAdapter = {
	addToCart: (selection: BaseSelection, itemId: string) => {
		gtag.addToCart({
			currency: selection.currency!,
			value: selection.totals?.grandTotalPriceAsNumber!,
			items: selection.items
				?.filter((item) => item.item === itemId || item.line === itemId)
				.map(selectionItemToGTAGProduct)!,
		});
	},

	viewCart: (selection: BaseSelection) => {
		gtag.viewCart({
			currency: selection.currency!,
			value: selection.totals?.grandTotalPriceAsNumber!,
			items: selection.items?.map(selectionItemToGTAGProduct)!,
		});
	},

	removeFromCart: (selection: BaseSelection, itemId: string) => {
		gtag.removeFromCart({
			currency: selection.currency!,
			value: selection.totals?.grandTotalPriceAsNumber!,
			items: selection.items
				?.filter((item) => item.item === itemId || item.line === itemId)
				.map(selectionItemToGTAGProduct)!,
		});
	},

	addToWishlist: (selection: BaseSelection, itemId: string) => {
		gtag.addToWishlist({
			currency: selection.currency!,
			value: selection.totals?.grandTotalPriceAsNumber!,
			items: selection.items
				?.filter((item) => item.item === itemId || item.line === itemId)
				.map(selectionItemToGTAGProduct)!,
		});
	},

	beginCheckout: (selection: BaseSelection) => {
		gtag.beginCheckout({
			currency: selection.currency!,
			value: selection.totals?.grandTotalPriceAsNumber!,
			items: selection.items?.map(selectionItemToGTAGProduct)!,
		});
	},

	purchase: (order: components["schemas"]["OrderCompleteModel"]) => {
		gtag.purchase({
			currency: order.currency!,
			value: order.totals?.grandTotalPriceAsNumber!,
			transaction_id: order.order!,
			items: order.items?.map(selectionItemToGTAGProduct)!,
		});
	},
};
