"use client";

import { clsx } from "@frend-digital/ui";
import { useControllableState } from "@frend-digital/ui/hooks";
import { Skeleton } from "../Skeleton";
import { Text } from "../Text";
import styles from "./index.module.css";

export function deepEqual<T>(x: T, y: T): boolean {
	if (x === y) {
		return true;
	}

	if (x && y && typeof x === "object" && typeof y === "object") {
		if (Object.keys(x).length !== Object.keys(y).length) {
			return false;
		}

		for (const key of Object.keys(x) as (keyof T)[]) {
			if (!deepEqual(x[key], y[key])) {
				return false;
			}
		}

		return true;
	}

	return false;
}

export interface Tab<T> {
	name: React.ReactNode;
	value: T;
}

interface Props<T> {
	tabs: Tab<T>[];
	children?: React.ReactNode;
	value?: T;
	// eslint-disable-next-line no-unused-vars
	onValueChange?: (value: T) => void;
	className?: string;
	size?: string;
}

export const TabBarSkeleton = ({ size = 3 }: { size?: number }) => {
	return (
		<div role="tablist" className={clsx(styles.root)}>
			{Array.from({ length: size }).map((_, i) => (
				<button role="tab" key={i} className={clsx(styles.tab)}>
					<Skeleton fitContent>
						<Text className={styles.tabName}>Lorem</Text>
					</Skeleton>
				</button>
			))}
		</div>
	);
};

export function TabBar<T = string>({
	tabs,
	value,
	onValueChange,
	className,
	size,
}: Props<T>) {
	const [state, setState] = useControllableState(
		undefined,
		value,
		onValueChange as React.Dispatch<React.SetStateAction<T>>,
	);

	return (
		<div
			role="tablist"
			className={clsx(styles.root, className, size === "large" && styles.large)}>
			{tabs.map((tab, index) => {
				const active = deepEqual(tab.value, state);
				return (
					<button
						role="tab"
						key={index}
						className={clsx(styles.tab, active && styles.active)}
						onClick={() => setState(tab.value)}>
						<Text className={styles.tabName} asChild={typeof tab.name === "object"}>
							{tab.name}
						</Text>
					</button>
				);
			})}
		</div>
	);
}
