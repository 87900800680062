"use client";

import { Text } from "@/components/ui/Text";
import type { ProductCardVariants } from "@/lib/centra/formatters";
import { clsx } from "@frend-digital/ui";
import Link from "next/link";
import styles from "./index.module.css";
import { ProductVariantImage } from "./ProductVariant";

export const ProductVariantsDisplay = ({
	currentProduct,
	variants,
}: {
	currentProduct: {
		uri: string;
		title: string;
	};
	variants?: ProductCardVariants;
}) => {
	if (!variants) return;

	const formattedVariants =
		variants?.length > 5 ? [...variants!.slice(0, 5), variants?.length - 5] : variants;

	return (
		<div className={styles.cardVariants}>
			{formattedVariants!.map((variant) => {
				if (typeof variant === "number") {
					return (
						<Link
							key={variant}
							className={clsx(styles.variantImg, styles.small)}
							href={`/product/${currentProduct.uri}`}
							title={currentProduct.title}>
							<Text variant="utility5">+{variant}</Text>
						</Link>
					);
				}

				const image =
					variant.media.find((img) => img.img_attributes_isPackshot === "1") ||
					variant.media.at(0)!;

				if (!image?.href) return null;

				return (
					<Link
						key={variant.id}
						className={clsx(
							styles.variantImg,
							styles.small,
							currentProduct.uri === variant.uri ? styles.borderBlack : "",
						)}
						href={`/product/${variant.uri!}`}
						title={variant.variantName}>
						<ProductVariantImage image={image} />
					</Link>
				);
			})}
		</div>
	);
};
