"use client";

import { clsx, Slot } from "@frend-digital/ui";
import { Portal } from "@radix-ui/react-portal";
import { createContext, useContext, useRef, useState, type ReactNode } from "react";
import styles from "./index.module.css";

interface TooltipContextType {
	isOpen: boolean;
	showTooltip: () => void;
	hideTooltip: () => void;
	updatePosition: () => void;
	position: { top: number; left: number };
	triggerRef: React.RefObject<HTMLButtonElement>;
}

const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

interface TooltipRootProps {
	children: ReactNode;
}

export const TooltipRoot = ({ children }: TooltipRootProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [position, setPosition] = useState({ top: 0, left: 0 });
	const triggerRef = useRef<HTMLButtonElement>(null!);

	const showTooltip = () => setIsOpen(true);
	const hideTooltip = () => setIsOpen(false);

	const updatePosition = () => {
		if (triggerRef.current) {
			const rect = triggerRef.current.getBoundingClientRect();
			setPosition({
				top: rect.top + window.scrollY - 8,
				left: rect.left + window.scrollX + rect.width / 2,
			});
		}
	};

	return (
		<TooltipContext.Provider
			value={{ isOpen, showTooltip, hideTooltip, updatePosition, position, triggerRef }}>
			{children}
		</TooltipContext.Provider>
	);
};

interface TooltipTriggerProps {
	children: ReactNode;
	className?: string;
	asChild?: boolean;
}

export const TooltipTrigger: React.FC<TooltipTriggerProps> = ({
	children,
	className,
	asChild,
}: TooltipTriggerProps) => {
	const Component = asChild ? Slot : "button";
	const { showTooltip, hideTooltip, updatePosition, triggerRef } = useTooltip();

	const handleMouseEnter = () => {
		updatePosition();
		showTooltip();
	};

	return (
		<Component
			className={clsx(styles.trigger, className)}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={hideTooltip}
			ref={triggerRef}>
			{children}
		</Component>
	);
};

interface TooltipContentProps {
	children: ReactNode;
	className?: string;
}

export const TooltipContent = ({ children, className }: TooltipContentProps) => {
	const { isOpen, position } = useTooltip();

	if (!isOpen) return null;

	return (
		<Portal>
			<div
				style={{
					top: position.top,
					left: position.left,
				}}
				className={clsx(styles.content, className)}
				role="tooltip">
				{children}
			</div>
		</Portal>
	);
};

const useTooltip = () => {
	const context = useContext(TooltipContext);
	if (!context) {
		throw new Error("TooltipContent must be used within a TooltipRoot");
	}

	return context;
};
