"use client";

import { Favourite } from "@/components/icons";
import { clsx } from "@frend-digital/ui";

import { useWishlist } from "@/lib/wishlist";
import type { ComponentProps } from "react";
import { IconButton, iconButtonColors } from "../iconButton";
import styles from "./index.module.css";

export const AddToWishlistButton = ({
	itemId,
	className,
	forceMount = true,
	color,
	...props
}: ComponentProps<"button"> & {
	forceMount?: boolean;
	itemId?: string;
	color?: keyof typeof iconButtonColors;
}) => {
	const { has, add, remove } = useWishlist();

	const itemInWishlist = has(itemId);

	return (
		<IconButton
			style={{ border: "none" }}
			size="medium"
			color={color ? color : "white"}
			onClick={() => (itemInWishlist ? remove(itemId) : add(itemId))}
			className={clsx(
				styles.root,
				className,
				(forceMount || itemInWishlist) && styles.forceMount,
			)}
			{...props}>
			<Favourite fill={itemInWishlist ? "black" : "transparent"} />
		</IconButton>
	);
};
