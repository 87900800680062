import { Skeleton } from "@/components/ui/Skeleton";
import styles from "./index.module.css";
export const ProductCardSkeleton = () => (
	<div
		style={{
			height: "100%",
			display: "flex",
			flexDirection: "column",
			gap: "15px",
			flex: "1",
		}}
		className={styles.skeleton}>
		<Skeleton className={styles.image} />
		<footer style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
			<Skeleton width={200} height="13px" />
			<Skeleton width={50} height="13px" />
		</footer>
	</div>
);
